* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px;
    height: 7vh;
    max-height: 7vh;
}

#left-nav img {
    cursor: pointer;
    margin-top: 20px;
}

#right-nav i {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    margin-left: 25px;
}

@media screen and (max-width: 650px) { 
    * {
        background-color: white;
    }
    nav {
        display: block;
        padding: 0;
    }
    #left-nav {
        margin-left: 20px;
    }
    #left-nav img {
        align-self: center;
    }
    #right-nav i {
        font-size: 20px;
        margin: 10;
    }
}