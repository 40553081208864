.home {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.left-section {
  margin-left: 50px;
  margin-top: 20%;
}

.left-section p {
  font-weight: 250;
}

#find-btn {
  color: white;
  font-size: 13px;
  font-weight: 600;
  background-color: #4968c7;
  width: 134px;
  height: 35px;
  border-radius: 5px;
  padding: 9px;
  padding-left: 30px;
}

.right-section {
  position: relative;
  margin-top: 10%;
}

.background-img {
  position: absolute;
  z-index: 0;
  right: -50%;
}

.phone-img {
  position: relative;
  z-index: 1;
  right: 60%;
}

.logo-img {
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media screen and (max-width: 650px) { 
    .home {
        flex-direction: column;
    }
    .left-section {
        align-self: center;
    }
    .right-section {
        /*margin-left: 50px;*/
        position: relative;
        align-self: end;
    }
}
